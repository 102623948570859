.pricing {
  .pricing-container {
    width: 80%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;

    border-radius: 10px;
    .pricing-image {
      img {
        width: 300px;
      }
    }
    .login-btn {
      height: 3rem;
      border: 1px solid #2772a4;
      width: 220px;
      background-color: transparent;
      color: #2772a4;
      border-radius: 9px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
    }
    .back-btn {
      height: 3rem;
      width: 50px;
      background-color: transparent;
      color: #2772a4;
      border: none;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
    }
    .back-btn:hover {
      color: #04283f;
      
    }
    .login-btn:hover {
      background-color: #2772a4;
      color: white;
    }
    .plan-container {
      margin: 8px 4px;
      width: 360px;
      background: white;
      border-radius: 20px;
      padding: 24px;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 550px;
      transition: transform 0.3s ease-in-out; // Smooth animation

      &.small {
        width: 370px;
        padding: 16px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .plan-icon {
        color: #2772a4;
      }

      .plan-metal {
        color: #2772a4;
        font-size: 19px;
        line-height: 28px;
        font-weight: 600;
        margin: 16px 0;
      }

      .price-container {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        white-space: nowrap;
      }

      .sub-price {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin: 8px 0;
        color: var(--neutral-dark);

        .amount {
          font-size: 18px;
          font-weight: 700;
          color: var(--neutral-dark);
        }

        .family-text {
          font-size: 17px;
          font-weight: 400;
          color: var(--neutral-dark);
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #ccc;
      }

      .benefits-title {
        font-size: 17px;
        margin: 16px 0;
        font-weight: 700;
        color: var(--neutral-dark);
        text-align: left;
      }

      .benefit-item {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 4px 0;
        color: #2772a4;

        .benefit-text {
          font-size: 16px;
          font-weight: 700;
          color: #2772a4;
          text-align: left;
        }
      }

      .action-buttons {
        margin-top: auto;
        display: flex;
        flex-direction: column;

        .buy-btn {
          height: 3rem;
          background-color: #2772a4;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease-in-out,
            transform 0.2s ease-in-out;

          font-weight: 600;
        }
        .buy-btn:hover {
          background-color: #0f4c75;
        }
      }
      // Scale up the entire plan-container when hovering over the buy button
      .plan-container:hover {
      }
    }
    .plan-container:hover {
      transform: scale(1.05);
      box-shadow: 3px 4px 12px lightgrey;
    }
  }
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: bold;
  color: #3597d3;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 20px;
  font-weight: bold;
  color: #09a479;
  color: #000;
}

@media screen and (max-width: 700px) {
  .pricing {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100vh;
  }
  .pricing .pricing-container {
    margin: 3px;
    width: 100%;
    border-radius: 0px;
  }
  .pricing .pricing-container .pricing-image img {
    width: 200px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .pricing .pricing-container .pricing-table table th,
  .pricing .pricing-container .pricing-table table td,
  .pricing
    .pricing-container
    .pricing-table
    table
    tr:first-child
    td:first-child {
    font-size: 12px;
  }
}
