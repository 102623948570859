
.actionselect{
    h2{
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        font-family: Montserrat', sans-serif';
        color: #09A479;
    }
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
}

.optionButton{
    // background-color: #276AFF;
    background-color: #3597d3;
    color: #fff;
    width: 230px;
    text-align: center;
    padding: 30px 30px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 20px;
    margin: 20px;
    cursor: pointer;
}

.selectButton{
    background-color: #3597d3;
    color: #fff;
    width: 230px;
    text-align: center;
    padding: 10px 30px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
}


@media screen and (max-width: 700px){

.selectButton{
    background-color: #3597d3;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}


}