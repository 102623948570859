.predashboard {
    height: 100vh;
    background-position: center;
    background-size: cover;
    padding: 50px 0px;
    h2 {
        text-align: center;
        font-size: 60px;
        margin-top: 30px;
        color: #fff;
        font-weight: 200;
        text-transform: uppercase;
    }

    .predashboard-center {
        text-align: center;
        width: fit-content;
        border-radius: 10px;
        margin: auto;
    }
}
.ant-layout-content {
    height: initial;
}
.ant-layout-sider{
    // min-width: 300px!important;
}

.user-details{
    padding: 30px;
    margin: 30px 30px;
    .user-details-block{
        background-color: #fff;
        padding: 30px;
        margin-bottom: 40px;
    }
}

.ant-layout-sider-zero-width-trigger{
    top: 34px;
}
.list_data{
    background-color: #fff;
    padding: 30px;
    font-size: 13px;
}


@media screen and (max-width: 700px ){
    .ant-layout-sider{
        min-width: 0px!important;
    }
    .user-details{
        margin: 0px;
        padding: 20px 0px;
    }
    .list_data{
        background-color: #fff;
        padding: 20px 10px;
        font-size: 13px;
    }
    .ant-list-item-meta-title{
        font-weight: bold;
    }
    .ant-list-item-meta-description{
        font-size: 13px;
    }
    .profile-top{
        padding: 0px 30px;
    }
}

.form-group1 {
    width: 100% !important;
}


// #components-layout-demo-responsive .logo {
//     height: 32px;
//     margin: 16px;
//     background: rgba(255, 255, 255, 0.2);
//   }
  
//   .site-layout-sub-header-background {
//     background: #fff;
//   }
  
//   .site-layout-background {
//     background: #fff;
//   }


// For ID Card
.id-container{
    width: 300px;
    height: 500px;
    margin: 0 auto;
    // box-shadow: 2px 5px 5px green;

    &-head {
        // height: 170px;
        text-align: center;
        // color: white;
        /*background: blueviolet;*/
        padding: 50px auto;
    }
    
    &-head img {
        width: 160px;
        height: 80px;
    }
    
    // h1, h2 {
    //     margin: 0;
    // }

    &-qr {
        background: rgb(74, 186, 112);
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        // position: relative;
        // top: -45px;
        /*left: -20px;*/
        margin: 15px auto;
    
        img {
            margin: 0 auto;
            display: block;
            width: 80px;
            // height: fit-content;
            box-shadow: 5px 2px 5px green;
            // border: 2px solid grey;
            background: whitesmoke;
        }
    }

    // &-body, &-image {
    //     margin: 0 auto;
    //     padding: 0;
    // }
    
    
    // .body .image img {
    //     width: 150px;
    //     height: 150px;
    //     border:  5px solid #4b97d4;
    //     border-radius: 50%;
    //     margin: 0 auto;
    //     display: block;
    // }
    
    &-body {
        // width: 150px;
        // height: 150px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // height: fit-content !important;
        padding: 0 20px;

        &-image {
            margin: 0 auto;
            padding: 0;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            border:  .5px solid #4b97d4;

            img {
                width: 100% !important;
                height: 100% !important;
                margin-top: 0 !important;
                box-shadow: 5px 5px 20px #4b97d4;
                // z-index: -1;
            }
        }

        &-name-div {
            margin: 0 auto;
            margin-top: 10px;
            // text-align: center;

            &-policy {
                position: relative;
                // bottom: -10px;
            }

            p {
                margin: 0;
                padding: 0;
            }

            p:first-child {
                margin: 5px 0;
                text-align: center;
            }

            
    
            &-name {
                color: #4b97d4;
                font-size: 1.2em;
                font-weight: bolder;
            }
            
            &-id {
                font-weight: bolder;
                color: #4b97d4;
            }
        }
    }
}

// .qr img {
//     margin: 0 auto;
//     display: block;
//     width: 80px;
//     /*height: 80px;*/
//     box-shadow: 5px 2px 5px green;
//     /*border: 2px solid grey;*/
//     background: whitesmoke;
// }

