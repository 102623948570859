body{
  background-color: #eff2f5;    
}

.layout{
    margin-top: 0px;
    padding-top: 0px;
    height: 100vh;
    .site-layout-content {
      min-height: 280px;
      padding: 14px;
      padding-top: 0px;
      background: #fff;
    }
    .ant-menu{
        justify-content: center;
    }
    #components-layout-demo-top .logo {
      float: left;
      width: 120px;
      height: 31px;
      margin: 16px 24px 16px 0;
      background: rgba(255, 255, 255, 0.3);
    }
    .ant-row-rtl #components-layout-demo-top .logo {
      float: right;
      margin: 16px 0 16px 24px;
    }
    a.ant-dropdown-link{
      color: #276AFF;
    }
}

.ant-modal-close{
  display: none;

  
}
.modal_block_logout{
  img{
    width: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  p{
    margin-bottom: 40px;
  }
  
}