
body{
    font-family: 'Montserrat', sans-serif;
}
.layout{
    display: flex;

}
.left-sidebar{
    width: 800px;
}

.right-sidebar{
    width: 100%;
}